<template>
  <span v-if="hasDoseValue" data-testid="insulin-dose-actual"
    >{{ dose.actualDose }} {{ dose.dosingDisplayUnit }}
  </span>
  <v-chip
    v-else
    label
    small
    color="#FDF7E6"
    text-color="#333"
    class="width-100-pct align-center justify-center"
    data-testid="bmg-chip-msg"
  >
    <span
      :class="{
        'deleted-dose-color': isDeleted,
      }"
    >
      {{ landscape ? 'Unknown dose' : 'Unknown' }}
    </span>
  </v-chip>
</template>
<script>
export default {
  name: 'InsulinDiaryDoseValue',
  props: {
    dose: { type: Object, required: true },
    landscape: { type: Boolean, required: true },
    isDeleted: { type: Boolean, required: false, default: false },
  },
  computed: {
    hasDoseValue() {
      return (
        this.dose.actualDoseType && this.dose.actualDoseType === 'specified'
      )
    },
  },
}
</script>
